import { Injectable } from "@angular/core";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";

import { AuthHttp } from "angular2-jwt";

import { Module, ModuleType } from "../_models/module";
import { getTcUrl } from "../../helpers/utils";

@Injectable()
export class ModulesService {
  constructor(private http: AuthHttp) {}

  getAll(): Observable<Module[]> {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/modules`)
      .map((res: Response) => res.json());
    return modules$;
  }

  getLastModules(): Observable<{ data: Module[] }> {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/modules?limit=60`)
      .map((res: Response) => res.json());
    return modules$;
  }

  get(id: number): Observable<Module[]> {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/modules/${id}`)
      .map((res: Response) => res.json().data);
    return modules$;
  }

  getModuleTypes(id: number): Observable<Module[]> {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/modules/${id}/types`)
      .map((res: Response) => res.json().data);
    return modules$;
  }

  getModuleTypeDetail(id: number): Observable<ModuleType> {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/module_types/${id}`)
      .map((res: Response) => res.json().data);
    return modules$;
  }

  updateModuleTypeRegistryConfig(id: number, data: any): Observable<any> {
    const apiUrl = getTcUrl();
    let result$ = this.http
      .post(`${apiUrl}/module_types/${id}`, data)
      .map((res: Response) => {
        return res && res.json();
      });
    return result$;
  }

  sendTestMessage(id: number, msg: any, region: string): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/send_test`, msg)
      .map((res: Response) => {
        return {
          result: res.json().result,
          msg: msg,
        };
      });
    return result$;
  }

  sendMessage(
    id: number,
    msg_code: any,
    data: any,
    region: string
  ): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/send_message`, {
        msg_code: msg_code,
        data: data,
      })
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  updateModule(id: number, params: any): Observable<any> {
    const apiUrl = getTcUrl();
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}`, params)
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  updateFirmware(id: number, region: string): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/update`, {})
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  restart(id: number, region: string): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/send_test`, {
        message: {
          cmd: 124,
          msg_id: 0,
          parser_id: 0,
        },
      })
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  ping(id: number, region: string): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/send_test`, {
        message: {
          cmd: 137,
          msg_id: 0,
          parser_id: 0,
        },
      })
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  info(id: number, region: string): Observable<any> {
    const apiUrl = getTcUrl({ region });
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/send_test`, {
        message: {
          cmd: 122,
          msg_id: 0,
          parser_id: 0,
        },
      })
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }

  getLocations(): Observable<any> {
    const apiUrl = getTcUrl();
    let locations$ = this.http
      .get(`${apiUrl}/modules/locations.geojson`)
      .map((res: Response) => res.json());
    return locations$;
  }

  forwardPostNotify(
    id: number,
    postBody: Record<string, any>
  ): Observable<any> {
    const apiUrl = getTcUrl();
    let result$ = this.http
      .post(`${apiUrl}/modules/${id}/forward_post_notify`, postBody)
      .map((res: Response) => {
        return {
          result: res.json().result,
        };
      });
    return result$;
  }
}
