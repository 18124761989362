export const navItems = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    title: true,
    name: "Manage",
  },
  {
    name: "Modules",
    url: "/modules",
    icon: "fa fa-rocket",
  },
  {
    name: "MQTT",
    url: "/mqtt",
    icon: "fa fa-laptop",
  },
  {
    name: "Module Types",
    url: "/module-types",
    icon: "icon-screen-desktop",
  },
  {
    title: true,
    name: "Admin",
    onlyAdmin: true,
  },
  {
    name: "Apps",
    url: "/apps",
    icon: "icon-puzzle",
    onlyAdmin: true,
  },
  {
    name: "Runtime Stats",
    url: "/runtime-stats",
    icon: "icon-speedometer",
    onlyAdmin: true,
  },
  // {
  //   name: 'Documentation',
  //   url: '',
  //   icon: 'fa fa-book',
  //   class: 'mt-auto',
  //   variant: 'success',
  //   badge: {
  //     variant: 'info',
  //     text: 'NEW'
  //   }
  // }
];
