import { Injectable } from "@angular/core";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";

import { AuthHttp } from "angular2-jwt";

import { getTcUrl } from "../../helpers/utils";

@Injectable()
export class AppService {
  constructor(private http: Http, private authHttp: AuthHttp) {}

  getCommands(): Observable<any> {
    const apiUrl = getTcUrl();
    return this.authHttp
      .get(apiUrl + "/commands")
      .map((response: Response) => {
        return response.json();
      });
  }

  getMessages(): Observable<any> {
    const apiUrl = getTcUrl();
    return this.authHttp
      .get(apiUrl + "/messages")
      .map((response: Response) => {
        return response.json();
      });
  }

  getWidgets(): Observable<any> {
    const apiUrl = getTcUrl();
    return this.authHttp
      .get(apiUrl + "/widgets")
      .map((response: Response) => {
        return response.json();
      });
  }
}
