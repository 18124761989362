import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import * as io from "socket.io-client";

import { getAllTcUrls } from "../../helpers/utils";
import { Subscriber } from "rxjs/Subscriber";

@Injectable()
export class SocketService {
  // private socket;
  private observables: Observable<any>[] = [];
  private observers: Subscriber<any>[] = [];
  private sockets: SocketIOClient.Socket[] = [];

  // getSocket(): SocketIOClient.Socket | undefined {
  //   return this.socket;
  // }

  reset() {
    for (let i = 0; i < this.observers.length; i++) {
      const observer = this.observers[i];
      try {
        observer.complete();
        observer.unsubscribe();
      } catch (error) {}
      this.observers[i] = undefined;
    }
    this.observers = [];
    for (let i = 0; i < this.observables.length; i++) {
      this.observables[i] = undefined;
    }
    this.observables = [];
    for (let i = 0; i < this.sockets.length; i++) {
      const socket = this.sockets[i];
      try {
        socket.close();
      } catch (error) {}
      this.sockets[i] = undefined;
    }
    this.sockets = [];
  }

  // sendMessage(message) {
  //   this.socket.emit("add-message", message);
  // }

  getMessages() {
    const wsUrls = getAllTcUrls({ ws: true });
    let socket: SocketIOClient.Socket | undefined;
    let observable = new Observable((observer) => {
      for (let i = 0; i < wsUrls.length; i++) {
        try {
          const wsUrl = wsUrls[i];
          socket = io(wsUrl, { transports: ["websocket"] });
          this.sockets.push(socket);

          //      socket.on('connect', () => {
          //        console.log(socket.id);
          //      });

          socket.on("message", (data) => {
            observer && observer.next(data);
          });
        } catch (error) {}
      }

      this.observers.push(observer);

      return () => {
        try {
          socket && socket.disconnect();
        } catch (error) {}
      };
    });
    this.observables.push(observable);
    return observable;
  }

  getConsole(module_id: string, region: string) {
    const wsUrls = getAllTcUrls({ ws: true, region });
    let socket: SocketIOClient.Socket | undefined;
    let observable = new Observable((observer) => {
      for (let i = 0; i < wsUrls.length; i++) {
        try {
          const wsUrl = wsUrls[i];
          socket = io(wsUrl, {
            query:
              "token=" +
              localStorage.getItem("token") +
              "&module_id=" +
              module_id,
            transports: ["websocket"],
          });
          this.sockets.push(socket);

          //      socket.on('connect', () => {
          //        console.log(this.socket.id);
          //      });

          socket.on("msg_" + module_id, (data) => {
            observer && observer.next({ eventType: "debug", message: data });
          });

          // polling events filtering
          socket.on("cmd_" + module_id, (data) => {
            console.log("CMD", data);
            observer && observer.next({ eventType: "polling", message: data });
          });
        } catch (error) {}
      }

      this.observers.push(observer);

      return () => {
        try {
          socket && socket.disconnect();
        } catch (error) {}
      };
    });
    this.observables.push(observable);
    return observable;
  }

  getUDPConsole(module_uid: string, region: string) {
    const wsUrls = getAllTcUrls({ ws: true, region });
    let socket: SocketIOClient.Socket | undefined;
    let observable = new Observable((observer) => {
      for (let i = 0; i < wsUrls.length; i++) {
        try {
          const wsUrl = wsUrls[i];
          socket = io(wsUrl, {
            query:
              "token=" +
              localStorage.getItem("token") +
              "&module_uid=" +
              module_uid,
            transports: ["websocket"],
          });
          this.sockets.push(socket);

          socket.on("udp_" + module_uid, (data) => {
            observer && observer.next({ eventType: "debug", message: data });
          });
        } catch (error) {}
      }

      this.observers.push(observer);

      return () => {
        try {
          socket && socket.disconnect();
        } catch (error) {}
      };
    });
    this.observables.push(observable);
    return observable;
  }

  getDebugConsole() {
    const wsUrls = getAllTcUrls({ ws: true });
    let socket: SocketIOClient.Socket | undefined;
    let observable = new Observable((observer) => {
      for (let i = 0; i < wsUrls.length; i++) {
        try {
          const wsUrl = wsUrls[i];
          socket = io(wsUrl, {
            query: "token=" + localStorage.getItem("token"),
            transports: ["websocket"],
          });
          this.sockets.push(socket);

          //      socket.on('connect', () => {
          //        console.log(this.socket.id);
          //      });

          socket.on("debug", (data) => {
            observer && observer.next(data);
          });
        } catch (error) {}
      }

      this.observers.push(observer);

      return () => {
        try {
          socket && socket.disconnect();
        } catch (error) {}
      };
    });
    this.observables.push(observable);
    return observable;
  }
}
