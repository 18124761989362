import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { tokenNotExpired, JwtHelper } from 'angular2-jwt';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {

        try {
            // migration to a new currentUser in local storage.
            // force a new login
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            if (!currentUser ||
                !currentUser.id) {
                throw "Invalid session";
            }

            // Check to see if a user has a valid JWT
            if (!tokenNotExpired()) {
                // If not thow error
                throw "Token expired";
            }

            return true;
            
        } catch (error) {
            console.error("AuthGuard error", error);
            // If error, they redirect them to the login page
            this.router.navigate(['/login']);
            return false;
        }
    }
}