export const environment = {
  production: false,
  API_URL: "https://api-staging.thingscloud.it/api/admin",
  WS_URL: "https://api-staging.thingscloud.it/",
  BUCKET_BASE_URL: "http://thingscloud.s3-website.eu-central-1.amazonaws.com",
  TC_SVR_PROTOCOL: "https",
  TC_SVR_DOMAIN: "thingscloud.it",
  TC_SVR_PORT: undefined,
  TC_ENV: "staging",
  MANAGE_REGION: "true",
  DEFAULT_REGION: "eu-central-1",
  ALL_REGIONS_IN_USE: "eu-central-1", // separated by commas
  TC_DOCKER: "false", // set "true" for frontend build with tc docker on backend side
};
