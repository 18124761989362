import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  //    model: any = {};

  loginData: any = { username: "", password: "" };

  loading = false;
  error = "";

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.loginData).subscribe(
      result => {
        if (result) {
          // login successful
          this.router.navigate(["/"]);
        } else {
          this.toastr.error("Authentication error");
          console.log(result);
          // login failed
          this.error = "Username or password is incorrect";
          this.loading = false;
        }
      },
      error => {
        this.toastr.error(
          JSON.parse(error._body).error || error,
          "Authentication error"
        );
        this.loading = false;
        console.log("Error");
        console.dir(error);
      },
      () => console.log("Finished")
    );
  }
}
