import { Injectable } from "@angular/core";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";

import { AuthHttp } from "angular2-jwt";

import { environment } from "../../environments/environment";

import { Module } from "../_models/module";
import { getTcUrl } from "../../helpers/utils";

@Injectable()
export class AppsService {
  constructor(private http: AuthHttp) {}

  getAll() {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/apps`)
      .map((res: Response) => res.json().data);
    return modules$;
  }

  get(id: number) {
    const apiUrl = getTcUrl();
    let modules$ = this.http
      .get(`${apiUrl}/apps/${id}`)
      .map((res: Response) => res.json().data);
    return modules$;
  }

  updateDetails(id: number, data: object) {
    const apiUrls = [];
    // must send the same call to all tc region instances
    // so every server instance update the new app data
    const allRegionsInUse = environment.ALL_REGIONS_IN_USE.split(",");
    for (let i = 0; i < allRegionsInUse.length; i++) {
      const regionInUse = allRegionsInUse[i];
      apiUrls.push(getTcUrl({ region: regionInUse }));
    }
    if (apiUrls.length === 0) {
      apiUrls.push(getTcUrl());
    }
    let modules$ = this.http
      .post(`${apiUrls[0]}/apps/${id}`, data)
      .map((res: Response) => res.json());
    // make call from index 1 to apiUrls.length
    // for index 0 is already done above (just for return the obj modules$)
    for (let i = 1; i < apiUrls.length; i++) {
      const apiUrl = apiUrls[i];
      const temp$ = this.http
        .post(`${apiUrl}/apps/${id}`, data)
        .map((res: Response) => res.json());
    }
    return modules$;
  }

  createApp(data: {
    name: string;
    username: string;
    code: string;
    password: string;
    apiToken: string;
  }) {
    const apiUrl = getTcUrl();
    let res$ = this.http
      .post(`${apiUrl}/apps`, data)
      .map((res: Response) => res.json());
    return res$;
  }

  createUpdateModuleType(data: {
    moduleTypeIdToUpdate?: number; // this is the id to check
    id?: number;
    code: string;
    name: string;
    run_bulk_update: boolean;
    bin_offset: string;
    bootloader_offset: string;
    bootloader_path: string;
    partitions_offset: string;
    partitions_path: string;
    flash_freq: string;
    config: string;
    enabled_cmds: string;
    boot_update: boolean;
    app_id: number;
    parsers: any[];
  }) {
    const apiUrl = getTcUrl();
    let url = `${apiUrl}/module_types/`;
    if (data.moduleTypeIdToUpdate) {
      url += `${data.moduleTypeIdToUpdate}/update`;
    } else {
      url += "create";
    }
    let res$ = this.http.post(url, data).map((res: Response) => res.json());
    return res$;
  }

  createFirmware(data) {
    const apiUrl = getTcUrl();
    let res$ = this.http
      .post(`${apiUrl}/firmwares/create`, data)
      .map((res: Response) => res.json());
    return res$;
  }

  deleteFirmware(id) {
    const apiUrl = getTcUrl();
    let res$ = this.http
      .post(`${apiUrl}/firmwares/delete`, { fw_id: id })
      .map((res: Response) => res.json());
    return res$;
  }

  setFirmwareType(id, fw_type) {
    const apiUrl = getTcUrl();
    let res$ = this.http
      .post(`${apiUrl}/firmwares/set_type`, {
        fw_id: id,
        fw_type: fw_type,
      })
      .map((res: Response) => res.json());
    return res$;
  }

  setModuleTypeSetup(id) {
    const apiUrl = getTcUrl();
    let res$ = this.http
      .post(`${apiUrl}/firmwares/set_setup`, {
        module_type_id: id,
      })
      .map((res: Response) => res.json());
    return res$;
  }
}
