import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./_common/auth.guard";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { LoginComponent } from "./views/login/login.component";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "base",
        loadChildren: "./views/base/base.module#BaseModule",
      },
      {
        path: "dashboard",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "stats",
        loadChildren: "./views/stats/stats.module#StatsModule",
      },
      {
        path: "modules",
        loadChildren: "./views/modules/modules.module#ModulesModule",
      },
      {
        path: "mqtt",
        loadChildren: "./views/modules/modules.module#ModulesModule",
      },
      {
        path: "apps",
        loadChildren: "./views/apps/apps.module#AppsModule",
      },
      {
        path: "module-types",
        loadChildren:
          "./views/module_types/moduletypes.module#ModuleTypesModule",
      },
      {
        path: "runtime-stats",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule",
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
