import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'
import { tokenNotExpired } from 'angular2-jwt';
import { Router } from '@angular/router';

import { getTcUrl } from '../../helpers/utils';

@Injectable()
export class AuthenticationService {
    public token: string;

    constructor(private router: Router,
        private http: Http) {
        // Capture the user credentials when the user has succesfully logged in

        //TODO: eventually get here the user profile
    }

    // Display the lock login box
    login(loginData: any): Observable<boolean> {
        const apiUrl = getTcUrl();
        return this.http.post(apiUrl + '/auth/login', loginData)
            .map((response: Response) => {
                // login successful if there's a jwt token in the response
                let data = response.json();
                let token = data.token;
                if (token) {
                    // set token property
                    this.token = token;

                    localStorage.setItem('token', response.json().token);
                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(data.user));

                    // return true to indicate successful login
                    return true;
                } else {
                    // return false to indicate failed login
                    return false;
                }
            });
    }

    // Logout the user
    logout() {
        // To log out, just remove the token and profile
        // from local storage
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');

        // Send the user back to the dashboard after logout
        this.router.navigateByUrl('/login');
    }

    // Check whether the user is logged in or not
    loggedIn() {
        return tokenNotExpired();
    }
}
